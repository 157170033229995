import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import ResetCss from '../components/resetCSS';
import SEO from '../components/seo';
import NotFound from '../containers/NotFound';

const NotFoundPage = () => {
  return (
    <Layout>
      <ResetCss />
      <SEO title="404: Not Found" description="This page does not exist on this site for a certified sleep consultant." />
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
