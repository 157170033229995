import { makeStyles } from '@material-ui/styles';
import { Link } from 'gatsby';
import * as React from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import LogoImage from '../../images/logo.png';
import { Goback, Icon, NotFoundContent, NotFoundWrapper } from './style';

interface NotFoundProps {}

const useStyles = makeStyles((theme) => ({
  mainLogo: {
    [theme.breakpoints.down('sm')]: {
      width: 250,
    },
  },
}));

const NotFound: React.FunctionComponent<NotFoundProps> = () => {
  const classes = useStyles();
  return (
    <NotFoundWrapper>
      <NotFoundContent>
        <h1>Invalid page</h1>
        <p>This page does not exist, use the Go Back button below.</p>
        <Goback>
          <Link to="/">
            <Icon>
              <IoMdArrowRoundBack />
            </Icon>
            Go Back
          </Link>
        </Goback>
      </NotFoundContent>
      <Link to="/">
        <img className={classes.mainLogo} src={LogoImage} alt="logo" />
      </Link>
    </NotFoundWrapper>
  );
};

export default NotFound;
